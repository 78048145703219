
import Header from "../components/Header";
import MainImage from "../components/MainImage";
import CalcForm from "../components/CalcForm";
import VideoContainer from "../components/VideoContainer";
import Content from "../components/Content";
import Carusel from "../components/Carusel";
import Footer from "../components/Footer";

const LandingPage = () => {
  return (
    <>
      <Header txt="Guadagna dai viaggi che fai già" />
      <MainImage />
      <CalcForm />
      <VideoContainer />
      <Content />
      <Carusel />
      <Footer />
    </>
  );
};

export default LandingPage;
