import classes from "./SocialNetworks.module.css";

import fbLogo from "../assets/fb-logo.png";
import inLogo from "../assets/in-logo.png";
import instaLogo from "../assets/insta-logo.png";

const SocialNetworks = () => {
  return (
    <div className={classes.socnet}>
      <h1>Seguici su</h1>
      <div className="container">
        <div className=" d-flex justify-content-center align-items-center">
          <div className=" d-flex justify-content-center">
            <div
              onClick={() => {
                window.open("https://www.facebook.com/pickpackcar", "_blank");
              }}
            >
              <img src={fbLogo} alt="" />
            </div>
          </div>
          <div
            className=" d-flex justify-content-center "
            style={{ margin: "0px 70px" }}
          >
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/pickpackcar",
                  "_blank"
                );
              }}
            >
              <img src={inLogo} alt="" />
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <div
              onClick={() => {
                window.open(
                  "https://www.instagram.com/pick_pack_car",
                  "_blank"
                );
              }}
            >
              <img src={instaLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialNetworks;
