import classes from "./Footer.module.css";
import logo from "../assets/img-logo-old.png";

import FooterBar from "./FooterBar";

const Footer = () => {
  return (
    <footer>
      <div
        className={
          classes.footer_logo_container + " d-flex justify-content-center"
        }
      >
        <div className={classes.footer_logo + " d-flex justify-content-center"}>
          <img src={logo} alt="" />
        </div>
      </div>
      <h2>ABBATTI SUBITO I COSTI DEI TUOI VIAGGI</h2>
      <FooterBar a={false} />
    </footer>
  );
};

export default Footer;
