import { Switch, Route, Redirect } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import ResultPage from "./pages/ResultPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import Feedback from "./pages/Feedback/Feedback";
// import TestPage from "./pages/TestPage";

function App() {
  return (
    <Switch>
      <Route path="/" exact>
        <LandingPage />
      </Route>
      <Route path="/result" exact>
        <ResultPage />
      </Route>
      <Route path="/privacy" exact>
        <PrivacyPage />
      </Route>
      <Route path="/feedback" exact>
        <Feedback />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default App;
