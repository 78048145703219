const PrivacyHTML = `<html>
<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--

 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Avenir Next LT Pro";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 56.7pt 56.7pt 56.7pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word, '>

<div class=WordSection1>

<p class=MsoNormal style='text-align:justify;line-height:21.0pt;'><b><span lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informativa sulla privacy</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:21.0pt;'><b><span lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in;
line-height:21.0pt;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informazioni generali </span></b></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:21.0pt;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>PickPackCar cooperazione d'impresa (con sede in Italia),
(&quot;PickPackCar&quot;, &quot;noi&quot;, &quot;nostro&quot; or &quot; a
noi&quot;), in qualità di titolare del trattamento dei dati, è impegnata a
proteggere e rispettare la privacy degli utenti. Questa informativa
(l’informativa sulla privacy) ha lo scopo di informare l’utente in merito alle
nostre pratiche relative a raccolta, utilizzo e divulgazione di informazioni
che l’utente potrebbe fornire tramite PickPackCar.eu o le nostre applicazioni
per dispositivi mobili (la Piattaforma).</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:21.0pt;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>La presente Informativa sulla privacy (unitamente ai nostri
Termini e Condizioni, eventuali altri documenti cui si fa riferimento in essa e
la nostra Informativa dei Cookie) stabilisce le basi sulle quali verranno
elaborati i dati personali che raccogliamo dall</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente o che l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
ci fornisce. Leggere attentamente quanto segue per comprendere le nostre
considerazioni e pratiche in materia di dati personali e di come verranno
trattati.</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:21.0pt;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:21.0pt;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:21.0pt;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Quali informazioni raccogliamo dall</span></b><b><span lang=IT
style='font-size:16.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente e per quanto tempo?</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:21.0pt;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Potremmo raccogliere ed elaborare i seguenti dati:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
text-indent:-.5in;line-height:21.0pt;'><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informazioni fornite dall</span><span lang=IT style='font-size:
12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>L</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente potrebbe fornirci informazioni, comprese informazioni di
identificazione personale (Dati personali) quando utilizza le nostre
Piattaforme, durante la compilazione di moduli sulle Piattaforme (ad esempio il
modulo di iscrizione), quando partecipa a uno qualsiasi dei nostri concorsi,
promozioni o sondaggi, quando comunica con noi per telefono, e-mail o in altro
modo e quando segnala un problema con le nostre Piattaforme.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Le informazioni
fornite possono includere:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Informazioni
obbligatorie per la registrazione per il servizio che forniamo sulle nostre
Piattaforme o per accedere ad altri servizi forniti da noi, compresi nome,
indirizzo e-mail, data di nascita, sesso, numero di cellulare e una password.
Tutti questi campi sono obbligatori. PickPackCar non sarà in grado di fornire i
servizi offerti sulle nostre Piattaforme se le informazioni richieste non sono
fornite e, di conseguenza, l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
non sar</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> in grado di registrare il proprio account utente sulle nostre
Piattaforme; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Una fotografia;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Un indirizzo
postale;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Dettagli delle
automobili;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Una
mini-biografia;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Uno storico di
ogni corrispondenza tra l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
e noi;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:1.0in;text-align:justify;
line-height:21.0pt;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>*Uno storico di
tutte le prenotazioni effettuate o di pubblicità.</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:35.4pt;line-height:
21.0pt;'><span lang=IT style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>2.2. Informazioni raccolte
automaticamente</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.2.1. Se l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente si iscrive attraverso i metodi di autenticazione su
social media, PickPackCar acceder</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> a determinati Dati personali (ad esempio nome, cognome, foto,
e-mail, numero di amici di Facebook ecc.) nell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account del social media dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>in base alle
condizioni di utilizzo applicabili di tali piattaforme di social media.
Potremmo inoltre raccogliere alcuni dei Dati personali dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente quando interagisce con funzionalit</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> di social media di terze parti, come ad esempio i “Likes”.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.2.2. Per
quanto riguarda ciascuna delle visite sulle nostre Piattaforme, potremmo
raccogliere, in conformità con le leggi vigenti e se necessario con il consenso
dell</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, informazioni relative ai dispositivi in uso e alle reti
alle quali si è connessi </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>quando si
utilizzano i nostri servizi. Ciò può includere le seguenti informazioni:
indirizzo IP, informazioni di login, tipo e versione del browser, tipi e
versioni dei plug-in del browser, sistema operativo e piattaforma,
identificativo pubblicità, informazioni </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>sulla visita
compreso il clickstream URL a, per e dalle nostre Piattaforme, prodotti
visualizzati o cercati, errori di download, durata delle visite a determinate
pagine, interazione nelle pagine e qualsiasi numero di telefono utilizzato per
chiamare il </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>nostro numero
del servizio clienti. Raccogliamo queste informazioni tramite l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utilizzo di varie tecnologie tra cui i cookie (per ulteriori
informazioni fare riferimento alla nostra Informativa sui cookie).</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.2.3.
Raccogliamo inoltre informazioni aggregate relative all</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>attivit</span><span lang=IT style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>
sulla nostra Piattaforma (ad esempio la quantit</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> di viaggi offerti, il tasso di risposta relative ai viaggi
inviate con o attraverso le nostre Piattaforme;Dettagli di movimenti contabili
o </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>finanziari,
ivi inclusi i movimenti effettuati attraverso le nostre Piattaforme o
altrimenti. Tali dati possono includere informazioni come ad esempio i dati
delle carte di pagamento o le coordinate bancarie, i dettagli dei viaggi o
delle tratte prenotati o </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>offerti
attraverso le nostre Piattaforme; </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Dettagli
delle visite alle nostre Piattaforme e delle risorse a cui si accede; Le
risposte a eventuali indagini o questionari, come ad esempio le recensioni sui
viaggi condivisi con altri utenti della nostra community. Tali informazioni
possono essere </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utilizzate
per scopi di analisi e comprensione degli utenti;Informazioni che potremmo
richiedere agli utenti quando segnalano un problema con le nostre Piattaforme o
il nostro servizio, come ad esempio l</span><span lang=IT style='font-size:
12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>oggetto
della richiesta di supporto; </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Informazioni
sulla posizione quando è stato fornito il consenso alla raccolta e al
trattamento di tali dati; Passaporto, patente di guida, carta d</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>identit</span><span lang=IT style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>
e altri documenti simili che l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
ha accettato di fornire. Tali informazioni possono essere </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>pubblicate
sul profilo pubblico dell</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
sulla nostra Piattaforma.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-align:justify;text-indent:-.5in;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Conservazione dei dati</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;text-align:justify;line-height:normal;
'><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal;'><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>2.3.1. Tranne per quanto riguarda le categorie di Dati personali
di cui alle clausole 2.3.2 e 2.3.3 di seguito, i Dati personali verranno
conservati per la durata del rapporto con noi e quindi non più utilizzati:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal;'><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>* 5 anni dopo l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>ultimo
utilizzo della nostra Piattaforma, se l</span><span lang=IT style='font-size:
12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
non chiude l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal;'><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>*1 anno dopo la chiusura dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account, a meno che l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
non abbia ricevuto una valutazione o una segnalazione negativa, nel quale caso
i suoi Dati personali sono conservati per (i) un periodo di 2 anni dopo l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>ultima valutazione o segnalazione negativa, o (ii) 1 anno dopo
la chiusura dell</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account, a seconda di quale periodo sia pi</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>ù</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> lungo.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal;'><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.3.2. Le
seguenti categorie di Dati personali possono essere conservati per durate
diverse:I Dati finanziari (ad es. pagamenti, rimborsi ecc.) vengono conservati
per la durata richiesta dalle leggi fiscali e contabili applicabili;Tutti i
contenuti generati </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>dagli utenti
(ad esempio commenti e valutazioni) sono resi anonimi, ma rimangono disponibili
sulle nostre Piattaforme.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>2.3.3. Nel
caso in cui l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account sia stato sospeso o bloccato, conserveremo i dati per un
periodo compreso tra 2 e 10 anni, al fine di impedire all</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente di eludere le norme che si applicano alle nostre
Piattaforme.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0in;text-align:justify;
text-indent:-.25in;line-height:normal;'><b><span lang=IT
style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Come utilizziamo le informazioni raccolte dall</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente?</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Useremo le informazioni
raccolte:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=EN-GB style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='margin-left:.5in;border-collapse:collapse;border:none'>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=EN-GB style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>SCOPI</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>LEGALI</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=EN-GB style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.1. Adempiere ai
  nostri obblighi derivanti da eventuali contratti stipulati tra l'utente e noi
  e per fornire le informazioni e i servizi richiesti dall'utente;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario per l'adempimento dei nostri
  obblighi contrattuali reciproci.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.2. Inviare informazioni
  correlate ai servizi via e-mail e/o messaggio di testo e/o qualsiasi altro
  mezzo di comunicazione (per esempio, la conferma della prenotazione);</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario per l'adempimento dei nostri
  obblighi reciproci e/o effettuato con il consenso dell'utente.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.3. Ricevere i
  pagamenti dall'utente o trasferire all'utente i pagamenti che riceviamo per
  suo conto;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario per l'adempimento dei nostri
  obblighi contrattuali reciproci.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.4. Consentire di
  personalizzare il profilo utente sulle nostre Piattaforme;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento viene effettuato con il consenso
  dell'utente.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.5. Consentire
  all'utente di comunicare e interagire con gli altri utenti in merito ai
  nostri servizi o ai viaggi che l'utente ha/condividerà con altri utenti e/o
  per organizzare</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario per l'adempimento dei nostri
  obblighi contrattuali reciproci e/o effettuato con il consenso dell'utente.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.6. Consentire
  l'accesso ai nostri servizi di assistenza e per consentire all'utente di
  comunicare con il team di PickPackCar,</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è (i) necessario per l'adempimento dei
  nostri obblighi contrattuali reciproci, (ii) effettuato con il consenso
  dell'utente e/o (iii) necessario per costituire, citare o difendere i diritti
  legali.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.7. Garantire il
  rispetto (1) delle leggi applicabili, (ii) delle Condizioni generali di
  utilizzo e (i) della nostra informativa sulla privacy. Alcune violazioni da
  nol considerate inappropriate potrebbero portare alla sospensione
  dell'account</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario (i) per l'adempimento dei
  nostri obblighi contrattuali reciproci, (ii) per la conformità ai nostri
  obblighi legali e/o (iii) per costituire, esercitare o difendere i diritti
  legali.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.8. Inviare, in
  conformità con le leggi vigenti e, ove necessario, con il consenso
  dell'utente, materiali di marketing e informazioni necessarie per agevolare
  il servizio o il processo di prenotazione e per fare suggerimenti e
  raccomandazioni su prodotti o servizi relativi ai nostri servizi che
  potrebbero interessare l’utente. Inoltre, utilizziamo i dati per indirizzare
  l'utente affinché vengano visualizzati i nostri annunci sulle piattaforme di
  social media. È possibile acquisire maggiori informazioni su queste
  funzionalità e sui dati ottenuti dall'utente visitando le sezioni pertinenti
  delle piattaforme di social media di terze parti, e</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è basato sul nostro interesse legittimo
  (ovvero, fornire all'utente contenuti significativi).</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.9. Informare
  l'utente di eventuali modifiche ai nostri servizi;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario (i) per l'adempimento dei
  nostri obblighi contrattuali reciproci, (ii) per la conformità ai nostri
  obblighi legali e/o (iii) per costituire, esercitare o difendere i diritti
  legali.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.10. Verificare
  le informazioni contenute in passaporto, patente di guida, carta d'identità e
  altri documenti di identità che possono essere raccolte al momento della
  registrazione o in qualsiasi altro momento durante l'utilizzo delle nostre
  Piattaforme; </span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è basato (i) sul consenso dell'utente, (ii)
  effettuato per costituire, ne esercitare o difendere i diritti legali e/o
  (iii) per la conformità ai nostri obblighi di legge.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>3.11. Gestire le
  nostre Piattaforme e per operazioni interne, tra cui risoluzione dei
  problemi, analisi dei dati, prove, ricerca, scopi di analisi e indagine;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Il trattamento è basato sul nostro legittimo interesse (vale a
  dire, garantire la sicurezza delle nostre Piattaforme e il miglioramento
  delle loro funzionalità).</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>3.12. Migliorare le nostre
  Plattaforme per garantire che il contenuto sia presentato nel modo più
  efficace per l'utente e per il relativo dispositivo;</span></p>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpMiddle style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è necessario (i) per l'adempimento dei
  nostri obblighi contrattuali reciproci e/o (ii) effettuato con il consenso
  dell’utente.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>3.13. Consentire all'utente di
  partecipare a funzionalità interattive del nostro servizio, quando sceglie di
  farlo;</span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraph style='margin:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>Questo trattamento è
  necessario (i) per l'adempimento dei nostri obblighi contrattuali reciproci
  e/o (ii) effettuato con il consenso dell’utente</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>3.14. Come parte dei nostri
  sforzi per mantenere le nostre Piattaforme sicure;</span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraphCxSpFirst style='margin:0in;line-height:normal'><span
  lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
  color:#212121'>Questo trattamento è basato (i) sul nostro interesse legittimo
  (garantire la sicurezza dee nostre piattaforme), (ii) effettuato per costituire,
  esercitare o difendere i diritti legali e/o (iii) per la conformità ai nostri
  obblighi di legge.</span></p>
  <p class=MsoListParagraphCxSpLast style='margin:0in;text-align:justify;
  line-height:normal;'><span lang=IT style='font-size:12.0pt;
  font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=652 valign=top style='width:488.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>3.15. Misurare o comprendere
  l'efficacia della pubblicità che serviamo agli utenti e presentare pubblicità
  rilevante per l'utente.</span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>
  </td>
  <td width=652 valign=top style='width:488.9pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoListParagraph style='margin:0in;text-align:justify;line-height:
  normal;'><span lang=IT style='font-size:12.0pt;font-family:
  "Avenir Next LT Pro",sans-serif;color:#212121'>Questo trattamento è basato su
  (i) il nostro interesse legittimo (ovvero, la misurazione e l'ottimizzazione
  dell'efficienza delle nostre campagne pubblicitarie) e/o (ii) il consenso
  dell'utente.</span></p>
  </td>
 </tr>
</table>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal;'><b><span lang=IT style='font-size:16.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal;'><b><span lang=IT style='font-size:16.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>    4. Chi sono i destinat</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span></b><b><span lang=IT style='font-size:16.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>ri delle Informazioni che
raccogliamo dall</span></b><b><span lang=IT style='font-size:16.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span></b><b><span lang=IT
style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
e per quali finalit</span></b><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>?</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.1. Quando si
utilizzano i nostri servizi, alcune informazioni sull</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente sono condivise con gli utenti delle nostre community, sia
sul profilo pubblico sia durante il processo di prenotazione (ad esempio,
quando viene pubblicato un viaggio il numero di telefono del Picker è
visualizzabile dagli utenti che sono autorizzati a prenotare quel viaggio). </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.2. Potremmo
ricevere e inviare informazioni sull</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente,
compresi i Dati personali, se l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
utilizza una qualsiasi delle Piattaforme gestite da noi o da altre entità e
affiliati PickPackCar, per le finalità indicate nella presente Informativa
sulla privacy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.3. Lavoriamo
inoltre a stretto contatto con terze parti che potrebbero essere i destinatari
dei Dati personali dell</span><span lang=IT style='font-size:12.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente, come ad
esempio:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* i nostri partner
commerciali che sono le piattaforme di social media e che possono offrire
servizi di collegamento, come ad esempio il collegamento delle informazioni al
profilo, dalle loro piattaforme di social media alle nostre Piattaforme; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* i nostri partner
commerciali che potrebbero pubblicizzare i propri servizi sulle nostre
Piattaforme e ai quale l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
potrebbe decidere di iscriversi: questi servizi possono essere servizi relativi
ai nostri servizi, come servizi assicurativi, servizi bancari, servizi di
noleggio ecc. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Per esempio,
potremmo fornire alle compagnie di assicurazione alcune delle informazioni dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente al fine di confermare che l</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente possa accedere alla copertura assicurativa;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* i nostri partner
commerciali che potrebbero pubblicizzare i nostri servizi sui loro siti Web; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* i nostri
subappaltatori per verifiche tecniche, dei pagamenti e dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>identit</span><span lang=IT style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>
e servizi di consegna, fornitori di analisi o agenzie di assicurazione sul
credito.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.4. Non divulghiamo
le informazioni forniteci, compresi i Dati personali dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, con nessuno di questi soggetti terzi, ad eccezione nei
casi in cui:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Sia necessario
coinvolgere un fornitore di servizi terzo, per l</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>adempimento di qualsiasi contratto stipulato con l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente al fine di facilitare o estendere i nostri servizi (per
esempio, se addebitiamo dei costi o riceviamo denaro dall</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente in relazione ai servizi sulle Piattaforme);</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Sia previsto come
parte del processo di prenotazione e al fine di fornire i servizi richiesti,
informazioni quali il nome, la fotografia, il numero di telefono cellulare e/o
l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>indirizzo di posta elettronica potrebbero essere visualizzate
sulle Piattaforme e/o trasmesse a un passeggero (se si è un autista) o un
conducente (se l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente è un passeggero);</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Sia previsto come
parte del nostro sistema di valutazione, le recensioni scritte dall</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente saranno pubblicate sulle Piattaforme. Le recensioni,
compreso il nome abbreviato e la fotografia, sono visibili a tutti i visitatori
delle Piattaforme; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Usiamo fornitori di
analisi e motori di ricerca come supporto nel miglioramento e nell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>ottimizzazione delle nostre Piattaforme; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Sia richiesto
esplicitamente dall</span><span lang=IT style='font-size:12.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente (per esempio,
quando si utilizzano metodi di autenticazione mediante social media); </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Potremmo distribuire
parti delle nostre Piattaforme (tra cui i viaggi pubblicati dall</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente) affinché vengano visualizzate sui siti Web dei nostri
partner commerciali attraverso API o widget. In questi casi, alcune
informazioni del profilo pubblico dell</span><span lang=IT style='font-size:
12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
potrebbero essere visualizzate su questi siti Web; </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>PickPackCar potrebbe
inoltre anche divulgare le informazioni se richiesto per legge o se in buona
fede dovesse ritenere che tale accesso, conservazione o divulgazione siano
ragionevolmente necessari per (i)rispondere ai reclami presentati contro
PickPackCar, (ii) conformarsi a eventuali procedimenti legali, (iii) far
rispettare qualsiasi accordo con i nostri utenti, come le Condizioni generali
di utilizzo, l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informativa sulla privacy, (iv) in caso di un</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>emergenza che implichi un pericolo per la salute pubblica, la
morte o lesioni fisiche di un soggetto (v) nel quadro di un</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>indagine o (vi) per proteggere i diritti, la propriet</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> o la sicurezza personale di PickPackCar, i suoi utenti o altri;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Nel caso in cui
dovessimo vedere o acquistare attivit</span><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> o beni, nel qual caso potremmo divulgare i Dati personali al
potenziale venditore o acquirente di tale attività o beni;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Se PickPackCar o
tutti o parte dei suoi beni vengono acquisiti da terzi, nel qual caso i Dati
personali in suo possesso relativi ai suoi utenti saranno uno dei beni
trasferiti.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.5. In conformità
con le leggi vigenti e, se necessario con il consenso dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, potremmo combinare informazioni relative all</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, compresi i Dati personali e le informazioni sui cookie,
che inviamo e riceviamo dai nostri partner commerciali. Potremmo utilizzare queste
informazioni e le informazioni combinate per le finalità di cui sopra.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>4.6. Si richiama l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>attenzione inoltre sul fatto che se l</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente decide di condividere le informazioni, compresi i Dati
personali, con noi attraverso i servizi di collegamento dei nostri partner
commerciali, oltre alla presente Informativa sulla privacy potrebbero essere
applicabili all</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente anche eventuali politiche e/o informative sulla privacy
dei nostri partner commerciali. Non controlliamo la raccolta e/o il trattamento
delle informazioni eventualmente eseguiti dai nostri partner commerciali sulle
loro piattaforme.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>5. Come utilizziamo
e moderiamo i messaggi?</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>5.1. Potremmo
rivedere, scansionare o analizzare i messaggi scambiati con gli altri utenti
della nostra community attraverso le nostre Piattaforme per la prevenzione
delle frodi, il miglioramento del servizio, ai fini di supporto al cliente, per
l</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>applicazione dei contratti stipulati con i nostri utenti (come
le Condizioni generali di utilizzo). Per esempio, al fine di evitare l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>aggiramento del nostro sistema di prenotazione on-line, potremmo
eseguire la scansione e analizzare i messaggi inviati attraverso le nostre
Piattaforme per controllare che non includano alcun dettaglio di contatto o
riferimenti ad altri siti Web.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>5.2. Non eseguiremo
mai la scansione né analizzeremo i messaggi dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente con altri utenti della nostra community per scopi
commerciali e pubblicitari. Potremmo utilizzare metodi automatizzati per
effettuare la moderazione di questi messaggi.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>6. Annunci mirati
sulle piattaforme di social media e sulle nostre comunicazioni inviate via
e-mail e/o SMS</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>In conformità alle
leggi vigenti e, se necessario con il consenso dell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, potremmo usare le informazioni fornite sulle nostre
Piattaforme per scopi di marketing diretto elettronico (per esempio, (i)
ricezione delle nostre newsletter, inviti ai nostri eventi o altre
comunicazioni che riteniamo potrebbero interessare l</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente o (ii) presentare all</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente pubblicit</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> mirata sulle piattaforme di social media o sui siti Web di
terzi).</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Per le comunicazioni
di marketing elettroniche:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>È possibile
ritirare il proprio consenso in qualsiasi momento (i) deselezionando la casella
corrispondente nell</span><span lang=IT style='font-size:12.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>account utente, (ii)
facendo clic sul link di cancellazione fornito in ogni comunicazione di
PickPackCar inviata all</span><span lang=IT style='font-size:12.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>attenzione dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente o (iii) contattandoci tramite i recapiti forniti nella
sezione 13 di seguito.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Per annunci e
contenuti mirati:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* sui social media
(per esempio Facebook e Twitter): è possibile opporsi in qualsiasi momento
configurando le impostazioni relative agli annunci tramite l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account di social media;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>*su siti Web terzi:
fare riferimento alla nostra Politica sui cookie per vedere come opporsi</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>7. Le informazioni
dell</span></b><b><span lang=IT style='font-size:16.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span></b><b><span lang=IT style='font-size:16.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>utente vengono trasferite? Come
e dove?&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Memorizziamo i Dati
personali dell</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente in nostro possesso nell</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Unione Europea (aUEa). Poiché siamo una societ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> internazionale, trasferiamo inoltre i Dati personali a Paesi
non europei in cui le leggi sulla protezione dei dati personali possono essere
meno protettive rispetto alla normativa in cui si vive. Questo succede quando
uno dei nostri responsabili al trattamento dei dati si trova in un Paese che
non fornisce un livello aadeguatoa di protezione dei dati, come ad esempio gli
Stati Uniti. Tale responsabile del trattamento può essere coinvolto, tra le
altre cose, nella risposta alla richiesta di assistenza dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente, nella moderazione delle immagini dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>account, nel trattamento dei dati di pagamento, nella fornitura
di servizi pubblicitari e di marketing per nostro conto e nella fornitura di
servizi di supporto attraverso comunicazioni elettroniche.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Ciò riguarda anche
i trasferimenti a società affiliate di PickPackCar situate al di fuori dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>UE a cui potremmo trasferire alcune delle informazioni dell</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente per la fornitura dei nostri servizi in questi Paesi.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Quando un tale
trasferimento avviene, ci assicuriamo che si svolga in conformit</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> alla presente Informativa sulla privacy e che sia regolato da
clausole contrattuali standard approvate dalla Commissione europea e che
garantiscano una protezione adeguata per gli interessati.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Se si desidera
ottenere ulteriori dettagli sulle misure di salvaguardia messe in atto, è
possibile contattarci tramite la sezione dei recapiti disponibili alla fine
dell</span><span lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:#212121'>ʼ</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informativa sulla privacy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>8. Quali sono i
diritti dell</span></b><b><span lang=IT style='font-size:16.0pt;font-family:
"Arial",sans-serif;color:#212121'>ʼ</span></b><b><span lang=IT
style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
in merito ai propri dati personali?</span></b><b><span lang=IT
style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>8.1. Ove
consentito dalla legge, l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
può avere il diritto di ottenere una copia dei suoi Dati personali in nostro
possesso. Prima di rispondere alla richiesta, potremmo chiedere all</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente di (i) verificare la propria identit</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> e (ii) fornire ulteriori dettagli </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>in modo che
possiamo rispondere meglio alla sua richiesta. Cercheremo di rispondere entro
un periodo di tempo appropriato e, in ogni caso, entro il periodo di tempo
richiesto dalla legge. Se l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
desidera esercitare tale diritto, deve contattarci </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-indent:
35.4pt;line-height:normal;'><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>tramite i
recapiti forniti di seguito (si veda sezione 13).</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>8.2. In qualit</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> di utente delle nostre Piattaforme, l</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente può accedere ai Dati personali in nostro possesso
tramite il proprio account per correggere, modificare o cancellare eventuali
informazioni inaccurate. L</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
può inoltre aggiornare o cancellare i propri Dati personali contattandoci,
utilizzando i recapiti forniti di seguito (si veda sezione 13). L</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente può chiudere il proprio account online in qualsiasi
momento e avere il diritto di opporsi al trattamento dei suoi Dati personali.In
alcuni casi, potremmo conservare alcune informazioni sull</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente come richiesto dalla legge o per scopi legittimi. Per
esempio, se crediamo che l</span><span lang=IT style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
abbia commesso frodi o violato le nostre Condizioni generali di utilizzo,
potremmo decidere di conservare alcune delle informazioni per evitare l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>aggiramento delle norme applicabili alle nostre Piattaforme.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>8.3. Se ritiene che
i propri diritti siano stati violati, l</span><span lang=IT style='font-size:
12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>utente
ha inoltre il diritto di presentare un reclamo all</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>autorit</span><span lang=IT style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span lang=IT
style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>
di controllo della protezione dei dati competente o adire le vie legali.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>8.4. L</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente ha altresì il diritto di ricevere i Dati personali che
lo riguardano, e da lui forniti a noi, in un formato strutturato, di uso comune
e leggibile a macchina, nonché di trasmettere tali Dati personali a un altro
titolare del trattamento dei dati.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>9. Cookie e tecnologie
simili&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Per maggiori
informazioni, consultare la nostra Politica sui cookie.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>10. Riservatezza
della password&nbsp;</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Nei casi in cui l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente abbia scelto una password che consente di accedere ad
alcune parti delle nostre Piattaforme, è responsabile di mantenere questa
password riservata. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Chiediamo all</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente di non condividere la password con nessuno.</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>11. Link ad altri
siti Web e social media</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Le nostre
Piattaforme potrebbero, di volta in volta, contenere link a e da siti Web delle
nostre reti o dei nostri inserzionisti e affiliati partner. Se l</span><span
lang=IT style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>utente segue un link a uno qualsiasi di questi siti Web, deve
ricordare che questi siti Web hanno le proprie pratiche in merito alla privacy
e che non accettiamo alcuna responsabilit</span><span lang=IT style='font-size:
12.0pt;font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>à</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> per tali politiche. Consultare queste politiche prima di
inviare Dati personali a questi siti Web.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>12. Modifiche all</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Arial",sans-serif;color:#212121'>ʼ</span></b><b><span
lang=IT style='font-size:16.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>Informativa sulla privacy</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Qualsiasi modifica
apportata alla nostra Informativa sulla privacy in futuro verr</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>à</span><span lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'> pubblicata in questa pagina. Se appropriato, informeremo gli
utenti o chiederemo loro di fornire il consenso. Consultare spesso questa
pagina per eventuali aggiornamenti o modifiche alla nostra Informativa sulla
privacy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><b><span lang=IT style='font-size:16.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>13. Recapiti e
Responsabile della Protezione dei Dati</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Se in qualsiasi
momento un utente desiderasse contattarci per esercitare i propri diritti o per
eventuali richieste relative ai Dati personali, può farlo contattando: </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>* Il nostro
Responsabile della Protezione dei Dati all</span><span lang=IT
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212121'>’</span><span
lang=IT style='font-size:12.0pt;font-family:"Avenir Next LT Pro",sans-serif;
color:#212121'>indirizzo </span><span lang=IT style='color:black'><a
href="mailto:info@pickpackcar.com"><span style='font-size:12.0pt;font-family:
"Avenir Next LT Pro",sans-serif'>info@pickpackcar.com</span></a></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:0in;text-align:justify;
line-height:normal;'><span lang=IT style='font-size:12.0pt;
font-family:"Avenir Next LT Pro",sans-serif;color:#212121'>Termini e Condizioni
d'uso &amp; Informativa sulla Privacy © 2020 PickPackCar</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=IT style='font-size:
12.0pt;line-height:115%;font-family:"Avenir Next LT Pro",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`;

export default PrivacyHTML;