import { makeStyles } from "@material-ui/core";
import React from "react";
import FeedbackForm from "./FeedbackForm";
import Footer from "../../components/Footer";

import bgImage from "../../assets/main-img.png";

const useStyles = makeStyles({
  container: {
    width: "100%",
    backgroundImage: `url("${bgImage}")`,
  },
  formWrapper: {
    padding: "40px 0",
    margin: "0 12px",
  },
});

const Feedback = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.formWrapper}>
        <FeedbackForm />
      </div>
      <Footer />
    </div>
  );
};

export default Feedback;
