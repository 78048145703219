import classes from "./Header.module.css";
import logo from "../assets/img-logo.png";

const Header = (props) => {
  return (
    <header>
      <div className={classes.header_container}>
        <div
          className={
            classes.padding_0 +
            "d-flex align-items-center justify-content-center"
          }
        >
          <div className={classes.header_logo}>
            <img src={logo} alt="" />
            <p>FastSocialDelivery</p>
          </div>
        </div>
        <div className={classes.padding_0}>
          <h1 className={classes.header_title}>PickPackCar</h1>
          <h3 className={classes.header_subtitle}>
            Be Fast, be Smart, be Social
          </h3>
        </div>
      </div>
      <h2 className={classes.last_title}>{props.txt}</h2>
    </header>
  );
};

export default Header;
