import classes from "./Carusel.module.css";

import avatar from "../assets/avatar.jpg";
import avatar2 from "../assets/avatar2.jpg";
import avatar3 from "../assets/avatar3.jpg";

const Carusel = () => {
  return (
    <div className={classes.wraper}>
      <div className={classes.carusel}>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
          data-interval="5000"
        >
          <ol className={classes.circle_indicators + " carousel-indicators"}>
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <h4 className={classes.carusel_title}>Cosa dicono di noi</h4>
            <div className="carousel-item active">
              <div className="row d-flex justify-content-center align-items-center">
                <div
                  className={
                    classes.picker +
                    " col-3 d-flex flex-column align-items-center"
                  }
                >
                  <div
                    className={
                      classes.carusel_img + " d-flex justify-content-center"
                    }
                  >
                    <img src={avatar} alt="" />
                  </div>
                  <div className="d-flex align-items-end flex-column">
                    <p>Federico</p>
                    <p>Picker</p>
                  </div>
                </div>
                <div className={classes.carusel_txt + " col-9"}>
                  {/* <h4>Cosa dicono di noi</h4> */}
                  <p>
                    Idea geniale, complimenti… faccio l’agente di commercio,
                    sempre in giro per l’Italia, da quando faccio il picker ho
                    recuperato un sacco di spese 👍
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row d-flex justify-content-center align-items-center">
                <div
                  className={
                    classes.picker +
                    " col-3 d-flex flex-column align-items-center"
                  }
                >
                  <div
                    className={
                      classes.carusel_img + " d-flex justify-content-center"
                    }
                  >
                    <img src={avatar2} alt="" />
                  </div>
                  <div className="d-flex align-items-end flex-column">
                    <p>Antonio</p>
                    <p>Picker</p>
                  </div>
                </div>
                <div className={classes.carusel_txt + " col-9"}>
                  {/* <h4>Cosa dicono di noi</h4> */}
                  <p>
                    Viaggio tutti i giorni con il treno tra Torino e Milano,
                    fare il picker è fantastico, ho guadagnoto già molto più di
                    quello che mi aspettavo
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="row d-flex justify-content-center align-items-center">
                <div
                  className={
                    classes.picker +
                    " col-3 d-flex flex-column align-items-center"
                  }
                >
                  <div
                    className={
                      classes.carusel_img + " d-flex justify-content-center"
                    }
                  >
                    <img src={avatar3} alt="" />
                  </div>
                  <div className="d-flex align-items-end flex-column">
                    <p>Lucia</p>
                    <p>Picker</p>
                  </div>
                </div>
                <div className={classes.carusel_txt + " col-9"}>
                  {/* <h4>Cosa dicono di noi</h4> */}
                  <p>
                    🙋‍♀️ Mi sono iscritta e sono una picker, ogni mese viaggio 4
                    volte da Rimini a Bologna e recuperare le spese di viaggio
                    non male, no no!?! 💰
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carusel;
