import classes from "./Content.module.css";
import img1 from "../assets/content-img-1.png";
import img2 from "../assets/content-img-2.png";
import img3 from "../assets/content-img-3.png";
import img4 from "../assets/content-img-4.png";

const Content = () => {
  return (
    <div className={classes.wraper}>
      <div className={classes.content}>
        <div className="row align-items-center">
          <div
            className={classes.imgContainer + " col-lg-6 order-2 order-lg-1 "}
          >
            <img src={img1} className={classes.content_img} alt="" />
          </div>
          <div className="col-lg-6 order-1 ">
            <div className={classes.site_content}>
              <h2>
                Abbatti i <span style={{ color: "#ff1616" }}>Costi</span> dei
                tuoi viaggi
              </h2>
              <p>
                Se viaggi spesso in macchina o hai un lungo viaggio in
                programma,{" "}
                <span style={{ fontWeight: "500" }}>PickPackCar</span> ti
                permette di azzerare il costo del tuo viaggio, risparmiando un
                gruzzoletto che potrai utilizzare per i tuoi hobby.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className={classes.imgContainer + " col-lg-5 order-2"}>
            <img src={img2} className={classes.content_img} alt="" />
          </div>
          <div className="col-lg-7 order-1 ">
            <div className={classes.site_content}>
              <h2>
                <span style={{ color: "#008037", margin: "0" }}>Decidi</span> ed
                Imposti tu data a orario
              </h2>
              <p>
                Decidi tu quando viaggiare, che sia di giorno o di notte, non
                dovrai cambiare o modificare i tuoi piani per essere un{" "}
                <span style={{ fontWeight: "500" }}>Picker</span>, basterà solo
                inserire la tua tratta sull’applicazione di{" "}
                <span style={{ fontWeight: "500" }}>PickPackCar</span> e
                accordarti con i{" "}
                <span style={{ fontWeight: "500" }}>sender</span> interessati!
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div
            className={classes.imgContainer + " col-lg-6 order-lg-1 order-2"}
          >
            <img src={img3} className={classes.content_img} alt="" />
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className={classes.site_content}>
              <h2>Ti organizzi direttamente con i SENDERS</h2>
              <p>
                Decidi tu quando viaggiare, che sia di giorno o di notte, non
                dovrai cambiare o modificare i tuoi piani per essere un{" "}
                <span style={{ fontWeight: "500" }}>PiCKER</span>, basterà solo
                inserire la tua tratta sull’applicazione di
                <span style={{ fontWeight: "500" }}>PickPackCa</span>r ed
                accordarti con i
                <span style={{ fontWeight: "500" }}>SENDER</span>
                interessati!
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className={classes.imgContainer + " col-lg-6 order-2 "}>
            <img src={img4} className={classes.content_img} alt="" />
          </div>
          <div className="col-lg-6 order-1 ">
            <div className={classes.site_content}>
              <h2>
                Tutti i pacchi{" "}
                <span style={{ color: "#008037" }}>ASSICURATI</span>
              </h2>
              <p>
                I pacchi che vengono spediti con la nostra applicazione vengono
                assicurati da PickPackCar e la persona che spedisce può
                scegliere un assicurazione supplementare. Qualunque danno
                accidentale non sarà di tua competenza. Tu devi solo pensare al
                trasporto
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
