import FooterBar from "../components/FooterBar";
import Header from "../components/Header";
import ResultBox from "../components/ResultBox";
import SocialNetworks from "../components/SocialNetworks";
import VideoContainer from "../components/VideoContainer";

import { useLocation, useHistory } from "react-router-dom";

const ResultPage = () => {
  const location = useLocation();
  const history = useHistory();
  if (!location.state) {
    history.replace({
      pathname: "/",
    });
  }
  console.log(location.state);
  return (
    <>
      <Header txt="Grazie per il tuo interesse" />
      <VideoContainer />
      <ResultBox result={location.state ? location.state.result : 0} />
      <SocialNetworks />
      <FooterBar a={true} />
    </>
  );
};

export default ResultPage;
