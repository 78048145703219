import classes from "./VideoContainer.module.css";

import video from "../assets/Video.mp4";

const VideoContainer = () => {
  const setVol = () => {
    document.getElementById("vv").volume = 0;
  };
  return (
    <div className={classes.video_container + " d-flex justify-content-center"}>
      <video
        id="vv"
        className={classes.vvv}
        controls
        onLoadStart={setVol}
        preload="auto"
        autoPlay={true}
        loop={true}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoContainer;
