import classes from "./ResultBox.module.css";

const ResultButton = (props) => {
  return (
    <div className={classes.result}>
      <h2>Il tuo risparmio mensile è</h2>
      <div className="container d-flex justify-content-center">
        <div className={classes.result_box + " d-flex justify-content-center"}>
          <p className={classes.price}>{props.result.toFixed(2)}€</p>
        </div>
      </div>
    </div>
  );
};

export default ResultButton;
