import privacy from "./components/Privacy.js";
import classes from "./PrivacyPage.module.css"

const PrivacyPage = () => {
    return (
        <>
        <div className={classes.privacy} dangerouslySetInnerHTML={{ __html: privacy }} />
        </>
    );
}

export default PrivacyPage;