import classes from "./FooterBar.module.css";

const FooterBar = (props) => {
  return (
    <div
      className={
        props.a
          ? classes.footer_barr +
            " " +
            classes.footer_bar +
            " d-flex justify-content-center"
          : classes.footer_bar + " d-flex justify-content-center"
      }
    >
      <div className={classes.footer_container}>
        <p>&copy;2021 All Right Reserved</p>

        <a href="# " className={classes.footer_link}>
          Terms
        </a>
        <a href="/privacy" className={classes.footer_link}>
          Privacy
        </a>
        <a href="/feedback" className={classes.footer_link}>
          Support
        </a>
        <a href="# " className={classes.footer_link}>
          Compliances
        </a>
      </div>
    </div>
  );
};

export default FooterBar;
