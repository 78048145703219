import classes from "./MainImage.module.css";
import image from "../assets/main-img.png";

const MainImage = () => {
  return (
    <div className={classes.main_image}>
      <img src={image} alt="" />
    </div>
  );
};

export default MainImage;
