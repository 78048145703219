import React, { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { validateEmail } from "../../utils/validation";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 600,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    padding: 20,
    boxSizing: "border-box",
    borderRadius: 20,
  },
  textField: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    height: 50,
  },
  menuItem: {},
  select: {},
});

const FeedbackForm = () => {
  const classes = useStyles();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    name: {
      value: "",
      error: null,
    },

    email: {
      value: "",
      error: null,
    },

    type: {
      value: "technical-support",
      error: null,
    },

    text: {
      value: "",
      error: null,
    },
    phoneModel: {
      value: "",
      error: null,
    },
  });

  const handleTypeChange = (evt) => {
    handleFieldChange("type", evt);
  };

  const handleFieldChange = (name, evt) => {
    const newValue = evt.target.value;
    const updatedFormData = { ...formData };

    updatedFormData[name] = { value: newValue, error: null };

    setFormData(updatedFormData);
    if (done) {
      setDone(false);
    }
  };

  const handleSubmit = async () => {
    const updatedFormData = { ...formData };
    let isValid = true;
    console.log("submited");

    if (updatedFormData["name"].value.length === 0) {
      isValid = false;
      updatedFormData["name"] = {
        ...updatedFormData["name"],
        error: "Name can not be empty",
      };
    }

    if (
      updatedFormData["email"].value.length === 0 ||
      !validateEmail(formData["email"].value)
    ) {
      isValid = false;
      updatedFormData["email"] = {
        ...updatedFormData["email"],
        error: "Email can not be empty",
      };
    }

    if (updatedFormData["text"].value.length === 0) {
      isValid = false;
      updatedFormData["text"] = {
        ...updatedFormData["text"],
        error: "Text can not be empty",
      };
    }

    if (updatedFormData["phoneModel"].value.length === 0) {
      isValid = false;
      updatedFormData["phoneModel"] = {
        ...updatedFormData["phoneModel"],
        error: "Phone model can not be empty",
      };
    }

    if (!isValid) {
      setFormData(updatedFormData);

      return;
    }

    const data = {
      name: formData["name"].value,
      email: formData["email"].value,
      type: formData["type"].value,
      text: formData["text"].value,
      phoneModel: formData["phoneModel"].value,
    };

    setLoading(true);

    await axios.post(
      "https://pick-pack-car-backend.herokuapp.com/general/report",
      data
    );

    setLoading(false);
    setShowSnackbar(true);
    setDone(true);
  };

  return (
    <Card className={classes.container}>
      <TextField
        label="Name"
        variant="outlined"
        className={classes.textField}
        value={formData.name.value}
        onChange={handleFieldChange.bind(this, "name")}
        error={!!formData.name.error}
        helperText={formData.name.error}
      />
      <TextField
        label="Registration E-mail"
        variant="outlined"
        className={classes.textField}
        value={formData.email.value}
        onChange={handleFieldChange.bind(this, "email")}
        error={!!formData.email.error}
        helperText={formData.email.error}
      />
      <FormControl variant="outlined" className={classes.textField}>
        <InputLabel id="feedback-type">Report type</InputLabel>
        <Select
          className={classes.select}
          labelId="feedback-type"
          value={formData.type.value}
          onChange={handleTypeChange}
          label="Report type"
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "center",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="technical-support" className={classes.menuItem}>
            Technical support
          </MenuItem>
          <MenuItem value="difficulties" className={classes.menuItem}>
            Difficulties with the app
          </MenuItem>
          <MenuItem value="suggestion" className={classes.menuItem}>
            Suggestion
          </MenuItem>
          <MenuItem value="remark" className={classes.menuItem}>
            Remark
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Question"
        variant="outlined"
        className={classes.textField}
        multiline
        minRows={6}
        maxRows={6}
        value={formData.text.value}
        onChange={handleFieldChange.bind(this, "text")}
        error={!!formData.text.error}
        helperText={formData.text.error}
      />

      <TextField
        label="Phone model"
        variant="outlined"
        className={classes.textField}
        value={formData.phoneModel.value}
        error={!!formData.phoneModel.error}
        onChange={handleFieldChange.bind(this, "phoneModel")}
        helperText={formData.phoneModel.error}
      />

      {loading ? (
        <CircularProgress style={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Button
          className={classes.button}
          disabled={done}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success" onClose={() => setShowSnackbar(false)}>
          Your report has been sent successfully. Thank you ❤️
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default FeedbackForm;
