import axios from "axios";
import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { useHistory } from "react-router-dom";

import classes from "./CalcForm.module.css";

let finalPrice;

const google = window.google;

let selectedCoordinates = [];

let distance;

const CalcForm = () => {
  let history = useHistory();

  const viaggiRef = React.useRef();
  const professioneRef = React.useRef();
  const emailRef = React.useRef();

  const [emailHasErr, setEmailHasErr] = React.useState();
  const [trattaHasErr, setTrattaHasErr] = React.useState();
  const [professionHasErr, setProfessionHasErr] = React.useState();
  const [viaggiHasErr, setViaggiHasErr] = React.useState();

  const validate = (event) => {
    event.preventDefault();
    emailRef.current.value = emailRef.current.value.trim();
    // eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    const newEmailErr = !re.test(emailRef.current.value);
    const newTrattaErr =
      selectedCoordinates[0] == null || selectedCoordinates[1] == null;
    const newProfessionErr = professioneRef.current.value === "";
    const newViaggiErr = viaggiRef.current.value === "";
    // console.log(viaggiRef.current.value);

    setEmailHasErr(newEmailErr);
    setTrattaHasErr(newTrattaErr);
    setProfessionHasErr(newProfessionErr);
    setViaggiHasErr(newViaggiErr);

    if (!(newTrattaErr || newProfessionErr || newEmailErr || newViaggiErr)) {
      gettingDistance(selectedCoordinates[0], selectedCoordinates[1]);
    }
  };

  const gettingDistance = (coordinatesA, coordinatesB) => {
    var service = new google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [coordinatesA],
        destinations: [coordinatesB],
        travelMode: "DRIVING",
      },
      callback
    );

    function callback(response, status) {
      // See Parsing the Results for
      // the basics of a callback function.
      // console.log(status);
      if (response.rows[0].elements[0].distance) {
        distance = Math.round(
          response.rows[0].elements[0].distance.value / 1000
        );
        calculation(distance);
      } else {
        alert("Invalid request");
      }
    }
  };

  const calculation = (distanceInKm) => {
    finalPrice = distanceInKm * 0.2 * parseInt(viaggiRef.current.value);
    // console.log(distanceInKm);
    // console.log(viaggiRef.current.value);
    // console.log(finalPrice);

    axios
      .post(
        "https://pick-pack-car-backend.herokuapp.com/general/landingEmail",
        {
          to: emailRef.current.value,
          price: finalPrice.toFixed(2),
        }
      )
      .then(() => {});

    // history.push({ pathname: "/result", state: { result: finalPrice } });
    history.push({
      pathname: "/result",
      state: { result: finalPrice },
    });
  };

  const setSelectedCoodinate = (id, value) => {
    selectedCoordinates[id] = value;
  };

  return (
    <>
      <div className={classes.tit + " d-flex justify-content-center"}>
        <p className={classes.calc_title}>
          Scopri quanti soldi puoi
          <span style={{ color: "#008037" }}>
            {" "}
            risparmiare mensilmente{" "}
          </span>{" "}
          dai tuoi viaggi con{" "}
          <span style={{ color: "#6fbdd6" }}> PickPackCar </span>
        </p>
      </div>
      <div className="row m-0">
        <div className="col-lg-12  d-flex justify-content-center flex-column align-items-center">
          <form onSubmit={validate} className={classes.form_container}>
            <div className="row justify-content-center">
              <div
                className={
                  classes.st +
                  " col-lg-6 col-xl-4 col-12 col-md-12 d-flex justify-content-center flex-column mobile-form"
                }
              >
                <label style={{ color: trattaHasErr ? "Red" : "#9094a1" }}>
                  Tratta
                </label>

                <div className="row d-flex justify-content-center">
                  <div
                    className={
                      classes.st +
                      " " +
                      classes.mb +
                      " col-12 col-md-6 d-flex justify-content-between align-items-center"
                    }
                  >
                    <PlacePicker id={0} callBack={setSelectedCoodinate} />
                  </div>
                  <div
                    className={
                      classes.st +
                      " " +
                      classes.mb +
                      " col-12 col-md-6 d-flex justify-content-between  align-items-center"
                    }
                  >
                    <PlacePicker id={1} callBack={setSelectedCoodinate} />
                  </div>
                </div>
              </div>

              <div
                className={
                  classes.st + " col-lg-6 col-xl-2 col-12 col-md-6 mobile-form"
                }
              >
                <label style={{ color: viaggiHasErr ? "Red" : "#9094a1" }}>
                  Viaggi Mensili
                </label>
                <div className="row d-flex justify-content-between align-items-center">
                  <div
                    className={
                      classes.st + " col-lg-12 d-flex justify-content-center"
                    }
                  >
                    <input
                      type="number"
                      min="1"
                      ref={viaggiRef}
                      className={classes.input_border + " text-center"}
                      placeholder="Quantita"
                      onFocus={(e) => {
                        e.target.placeholder = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.placeholder === "") {
                          e.target.placeholder = "Quantita";
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  classes.st +
                  " " +
                  classes.marg +
                  " col-lg-6 col-xl-2 col-12 col-md-6 mobile-form"
                }
              >
                <label style={{ color: professionHasErr ? "Red" : "#9094a1" }}>
                  Professione
                </label>
                <div className="row d-flex justify-content-between align-items-center">
                  <div
                    className={
                      classes.st + " col-lg-12 d-flex justify-content-center"
                    }
                  >
                    <input
                      type="text"
                      ref={professioneRef}
                      className={classes.input_border + " text-center"}
                      placeholder="Professione"
                      onFocus={(e) => {
                        e.target.placeholder = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.placeholder === "") {
                          e.target.placeholder = "Professione";
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  classes.st +
                  " " +
                  classes.marg +
                  " col-lg-6 col-xl-2 col-12 col-md-12 mobile-form"
                }
              >
                <label style={{ color: emailHasErr ? "Red" : "#9094a1" }}>
                  EMAIL
                </label>
                <div className="row d-flex justify-content-between align-items-center">
                  <div
                    className={
                      classes.st + " col-lg-12 d-flex justify-content-center"
                    }
                  >
                    <input
                      type="text"
                      ref={emailRef}
                      className={classes.input_border + " text-center"}
                      placeholder="Email"
                      onFocus={(e) => {
                        e.target.placeholder = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.placeholder === "") {
                          e.target.placeholder = "Email";
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  classes.st +
                  " col-lg-6 col-xl-2 col-12 col-md-12 d-flex justify-content-center align-items-center"
                }
              >
                <button type="submit" className={classes.calc_button}>
                  CALCOLA
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const PlacePicker = (props) => {
  const [address, setAddress] = React.useState("");
  // const [coordinates, setCoordinates] = React.useState({
  //   lat: null,
  //   lng: null,
  // });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    //setCoordinates(latLng);
    props.callBack(props.id, latLng);
  };
  const place = props.id === 0 ? "Partenza" : "Arrivo";
  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="w-100">
          <input
            // onChange={(e) => (e.target.placeholder === "" ? place : null)}
            style={{ paddingRight: "5px" }}
            className={classes.input_border + " text-center"}
            {...getInputProps({
              placeholder: props.id === 0 ? "Partenza" : "Arrivo",
              onFocus: (e) => (e.target.placeholder = ""),
              onBlur: (e) => {
                if (e.target.placeholder === "") {
                  e.target.placeholder = place;
                }
              },
            })}
          />

          <div
            className={classes.ww}
            style={{
              position: "absolute",
              zIndex: "2",
              backgroundColor: "white",
              borderRadius: "10px",
              marginTop: "6px",
              boxShadow:
                suggestions.length !== 0
                  ? "0 0 8px 2px rgba(199, 191, 191, 0.2), 0 0 8px 2px rgba(199, 191, 191, 0.2)"
                  : null,
            }}
          >
            {loading ? <p>Loading ...</p> : null}
            {suggestions.map((suggestion) => {
              const style = {
                borderRadius: "10px",
                padding: "2px",
                margin: "5px",
                backgroundColor: suggestion.active ? "#ADFEFF" : "transparent",
              };
              return (
                <div
                  className={classes.ccc}
                  key={suggestion.description}
                  {...getSuggestionItemProps(suggestion, { style })}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default CalcForm;
